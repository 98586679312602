<template>
  
  <div >
    <DropZone 
      :multiple="true"
      :dropzone="true"
      :hoverable="true"  
      :types="['video', 'image']"
    />

  </div>
</template>

<script>
import DropZone from '@/@core/components/forms/files/DropZone.vue';
import {
  BCard, BImg, BButton
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex';

export default {
  components: 
  {
    BCard, BImg, BButton,
    DropZone
  },
  data() {
    return {
      files: []
    }
  },
  props: ['confirmDeleteFullItem'],
  mounted() {
  },
  methods: {
   
  },

  computed: {
  }
}

</script>