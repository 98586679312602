<template>
  <!-- main drop zone -->
  <div class="d-flex drop-zone-container">
    <!-- Floating Button -->
    <b-button
      v-if="dropzone"
      variant="primary"
      class="dz-floating-button"
      @click='openUpload'
    >
      <feather-icon
        icon="PlusIcon"
        size="18"
      ></feather-icon>

      {{ $t('Add Files') }}
    </b-button>
    
    <input
      style="visibility: hidden; width: 0px"
      type="file"
      :accept="acceptedTypes"
      :id="`file-input-${name}`"
      :multiple="multiple || false"
      @change="inputUpload"
    />
    
    <div 
      v-if="dropzone"
      class="drop-zone card"
      :class="{ danger: rules, 'col-9': hoverable, 'col-12': !hoverable }"
      id="drop-zone-element"
      @dragover.prevent="addHoverEffect"
      @drop.prevent="itemsDropped"
      @dragleave.prevent="removeHoverEffect"
    >
      <div 
        class="d-flex flex-wrap"  
      >
        <FileCard 
          v-for="file in dropzoneFiles"
          :key="file.id"
          :file="file"
          @hover-file="setHoverableObj"
          @delete-file="deleteFlow"
        ></FileCard>
      </div>
      <div
        v-if="dropzoneFiles.length == 0"
        class="dz-content"
      >
        <div class="d-flex justify-content-center">
          <img 
            v-if="types && types.includes('video')"
            width="70"
            height="70"
            src="./video-placeholder.png"
          />
          <h2 v-if="types && types.includes('video') && types.includes('image')" class="my-2 mx-3"> OR </h2>
          <img 
            v-if="!types || (types && types.includes('image'))"
            width="70"
            height="70"
            src="./img-placeholder.png"/>
        </div>
        <h4 class="mt-2"> 
          Click 
          <span @click="openUpload" class="text-primary pointer text-underline"> {{ $t('Here') }} </span> 
          Or Drag & Drop Files
        </h4>
      </div>
      
      
    </div>

    <!-- hoverable file details -->
    <HoverableObject 
      v-if="hoverable"
      :hoveredFileObj="hoveredFileObj"
      @dataFieldUpdated="getFilesData"
    ></HoverableObject>

    <!-- Uploading Error  -->
    <UploadingError
      v-if="uploadingErr.length > 0" 
    ></UploadingError>

    <!-- Uploading progress  -->
    <UploadingProgress
      v-if="uploadingFiles.length > 0" 
      @closeProgress="closeProgress"
      @openProgress="openProgress"
      :isUploadingProgressClosed="isUploadingProgressClosed"
    ></UploadingProgress>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import {
  BButton
} from 'bootstrap-vue';
import FileCard from './FileCard.vue';
import HoverableObject from './HoverableObject.vue';
import UploadingProgress from './UploadingProgress.vue';
import UploadingError from './UploadingError.vue';

export default {
  components: {
    BButton, FileCard,
    UploadingProgress,
    HoverableObject,
    UploadingError,
  },
  data() {
    return {
      files: [],
      filesPromises: [],
      hoveredFileObj: {},
      showHoveredFile: {},
      isUploadingProgressClosed: false,
    }
  },
  props: [
    'name',
    'types',
    'multiple',
    'dropzone',
    'rules',
    'hoverable',
  ],
  mounted() {
    this.getFilesData({});
  },
  methods: {
    ...mapActions(['progressUploadFile', 'getDropzoneFiles', 'deleteFile', 'getFileById', 'getMedia', 'deleteMedia']),
    getFilesData() {
        this.getDropzoneFiles({
        //   original: true,
          // mime_type: this.mimeTypeLike,
        //   mime_type_like: this.mimeTypeLike,
        //   with: {
        //     childs: {
        //       metadata: ['thumbnail', 'small']
        //     }
        //   }
        }).then(res => {
          
        })
    },
    uploadAndReloadData() {
      // this.progressUploadFile(newFile);

      Promise.all(this.filesPromises)
      .then(res => {
        this.filesPromises = [];

        this.getFilesData();
        if(res.length == 1) {
          this.$emit('fileUploaded', { file: res[0], field: this.field} );
        } 
      }).catch(err => {
        this.getFilesData();
        this.filesPromises = [];
      })
    },
    createUploadArray(newFile) {
      let uploadPromise = this.progressUploadFile;
      this.filesPromises.push(uploadPromise({...newFile, attachToMedia: false }));
    },
    openUpload() {
      let input = document.getElementById('file-input-' + this.name);
      if(input) {
        input.click();
      }
    },
    inputUpload(event) {
      let fileList = event.target.files;
      Array.from(fileList).forEach(file => {
        let src = URL.createObjectURL(file);
        let upId = uuidv4();
        let newFile = {
          file,
          src,
          id: upId
        };
        this.files.push(newFile);
        this.createUploadArray(newFile);
        // this.$emit('fileUploaded', { file: newFile, field: this.field });
      })
      this.uploadAndReloadData();
    },
    itemsDropped(event) {
      this.removeHoverEffect();
      let fileList = event.dataTransfer.files;
      if(this.multiple) {
        Array.from(fileList).forEach(file => {
          if(this.checkIfFileTypeAccepted(file)) {
            let src = URL.createObjectURL(file);
            let upId = uuidv4();
            let newFile = {
              file,
              src,
              id: upId
            }
            this.files.push(newFile);
            this.createUploadArray(newFile);
          }
        })
        this.uploadAndReloadData();
        
      } else {
        if(this.checkIfFileTypeAccepted(fileList.item(0))) {
          let src = URL.createObjectURL(fileList.item(0));
          let upId = uuidv4();
          let newFile = {
            file: fileList.item(0),
            src,
            id: upId
          }
          this.files.push(newFile);
          this.createUploadArray(newFile);
          this.uploadAndReloadData();
          // this.$emit('fileUploaded', { file: newFile, field: this.field });
        }
      }
    },
    checkIfFileTypeAccepted(file) {
      if(!this.types) {
        if(file.name.match(/\.(jpg|jpeg|png|gif|svg|nef|dng|webp)$/i)) return true;
      }
      if(this.types && this.types.includes('video')) {
        if(file.name.match(/\.(mp4|m4v|mpv|mov|mpeg|mpg)$/i)) return true;
      }
      if(this.types && this.types.includes('image')) {
        if(file.name.match(/\.(jpg|jpeg|png|gif|svg|nef|dng|webp)$/i)) return true;
      }
      return false;
    },
    openSelectPopup() {
      this.openModal({item: this.field}, 'SelectImage');
    },
    async deleteFlow(file) {
      if (file.media_id) {
        await this.deleteMedia(file.media_id);
      }
      await this.deleteFile(file.id) 
        .then(res => {
          // this.showSelectedFile(); 
          this.getFilesData({})
          if(this.hoveredFileObj.id == file.id) {
            this.hoveredFileObj = {}
          }
        }).catch(err => {
          
        })
    },
    setHoverableObj(file) {
      this.hoveredFileObj = file;
    },
    addHoverEffect() {
      let element = document.getElementById('drop-zone-element');
      element.classList.add('file-drag')
    },
    removeHoverEffect() {
      let element = document.getElementById('drop-zone-element');
      element.classList.remove('file-drag')
    },
    closeProgress() {
      setTimeout(() => {
        this.isUploadingProgressClosed = true;
      }, 50);
      },
      openProgress() {
      setTimeout(() => {
        this.isUploadingProgressClosed = false;
      }, 50);
    }
  },

  computed: {
    ...mapGetters(['uploadingFiles', 'uploadingErr', 'dropzoneFiles']),
    acceptedTypes() {
      let accept = '';
      if(!this.types) {
        accept += 'image/*';
      } 
      if(this.types && this.types.includes('video')) {
        accept += 'video/mp4,video/x-m4v,video/*,';
      }
      if (this.types && this.types.includes('image')) {
        accept += 'image/*';
      }
      return accept;
    },
    mimeTypeLike() {
      if(!this.types) {
        return 'image';
      } 
      if(this.types && this.types.includes('video')) {
        return 'video';
      }
      if (this.types && this.types.includes('image')) {
        return 'image';
      }
    },
  }
}
</script>


<style lang="scss">
@import './index.scss';
</style>