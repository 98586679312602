<template>
<div class="dz-err-container text-center card mt-2">
  <h4 class="text-warning my-2"> 
    Error Uploading These Files 
    <feather-icon
      icon="XIcon"
      size="18"
      class=" cursor-pointer mx-2"
      @click="clearErrs"
      style="float: right"
    />
  </h4>
  <div 
    class="dz-err"
  >
    <div
      v-for="file in uploadingErr"
      :key="file.payload.id"
      class="text-center"
    >
    
      <div class="dz-img-err-container" :id="file.payload.id">
        <img 
          :src="file.payload.src"
          width="40"
          height="40"
          class="mx-2"
        />
        <p class="mx-2 mt-1">
          {{file.payload.file.name}}
        </p>
        <!-- <div class="dz-img-err mx-2"></div> -->
      </div>
      <b-tooltip :target="file.payload.id" :title="file.err.message"></b-tooltip>
    </div>
  </div>

</div>

</template>

<script>
import { mapGetters } from 'vuex';
import {
  BTooltip,
} from 'bootstrap-vue';

export default {
  components: {
    BTooltip
  },
  methods: {
    clearErrs() {
      this.$store.commit('clearUploadingErr');
    },
  },
  computed: {
   ...mapGetters(['uploadingErr']) 
  }
}
</script>